@import url("https://fonts.googleapis.com/css2?family=Bangers&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter");

:root {
  --bg: #040014;

  --primary: #5fdf0a;
  --primary-rgb: 95, 223, 10;

  --secondary: #4dbf01;
  --secondary-rgb: rgb(77, 191, 1);

  --dark: #191919;
  --dark-rgb: 25, 25, 25;

  --light: #ffffff;
  --light-rgb: 255, 255, 255;
}

* {
  cursor: default;
  font-family: "Inter", sans-serif;
}

body {
  overflow: hidden;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99;
  transform: translate(-50%, -50%);
  text-align: center;
}

.main-btn {
  font-size: 22px;
  letter-spacing: 2px;
  font-family: "Bangers", sans-serif;
  background-color: var(--primary);
  color: var(--dark);
  border-radius: 50px;
  border: none;
  outline: none;
  padding: 5px 25px;
  transition: 0.15s ease-in;
}

.main-btn i,
.main-btn img {
  cursor: pointer;
}

.main-btn:disabled {
  cursor: not-allowed;
}

.main-btn:hover {
  filter: sepia(0.5);
}

.qty {
  font-size: 46px;
  font-weight: 800;
  font-family: "Bangers", sans-serif;
  letter-spacing: 4px;
}

.text-circle {
  background: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin: 5px;
  overflow: hidden;
  padding: 25px;
  position: relative;
}

.text-inside-circle {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.buy-qty-text {
  font-family: "Bangers", sans-serif;
  color: var(--primary);
  font-size: 28px;
}

.plus-minus-btn {
  outline: none;
  border: none;

  font-size: 36px;
  text-align: center;
  width: 50px;
  padding: 3px 15px;
  margin-bottom: 25px;
  background-color: var(--primary);
  cursor: pointer;
}

.plus-minus-btn:hover {
  filter: sepia(0.5);
}

.cost {
  font-family: "Bangers", sans-serif;
  letter-spacing: 1px;
  font-size: 32px;
  color: var(--light);
}

.sloth-1 {
  animation: slothOneMove 4s infinite alternate-reverse;
}

@keyframes slothOneMove {
  0% {
    transform: translateX(0%) translateY(0%) scale(0.85);
  }

  100% {
    transform: translateX(6%) translateY(2%) scale(1);
  }
}

.sloth-2 {
  animation: slothTwoMove 3s infinite alternate-reverse;
}

@keyframes slothTwoMove {
  0% {
    transform: translateX(0%) translateY(3%) scale(0.9);
  }

  100% {
    transform: translateX(-6%) translateY(5%) scale(1);
  }
}

@media (min-width: 992px) and (max-width: 1440px) {
  .sloth-1 {
    width: 35% !important;
    bottom: 50px !important;
  }

  .sloth-2 {
    width: 35% !important;
    right: -50px !important;
    bottom: 40px !important;
  }

  .trunk {
    left: 40px !important;
  }
}

.trunk {
  animation: trunkMove 2s infinite alternate-reverse;
}

@keyframes trunkMove {
  0% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(-20px);
  }
}

.leaf-1 {
  animation: leafOneMove 1.5s infinite alternate-reverse;
}

@keyframes leafOneMove {
  0% {
    transform: skewX(0deg) skew(0deg);
  }
  100% {
    transform: skewX(2deg) skew(1deg);
  }
}

.leaf-2 {
  animation: leafTwoMove 1.8s infinite alternate-reverse;
}

@keyframes leafTwoMove {
  0% {
    transform: skewX(0deg) skew(0deg);
  }
  100% {
    transform: skewX(-3deg) skew(-1deg);
  }
}

::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 600px) {
  .qty {
    font-size: 40px;
  }

  .main-btn {
    font-size: 16px;
    padding: 0px 20px;
  }

  .sloth-1 {
    z-index: 1;
  }
}

.container-fluid {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  background: #fff;
}

.background {
  animation: Gradient 15s ease infinite;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0px;
  background-size: cover;
  -webkit-animation: slidein 50s;
  animation: slidein 50s;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

@-webkit-keyframes slidein {
  from {
    background-position: top;
    background-size: 2000px;
  }
  to {
    background-position: -100px 0px;
    background-size: 2750px;
  }
}

@keyframes slidein {
  from {
    background-position: top;
    background-size: 2000px;
  }
  to {
    background-position: -100px 0px;
    background-size: 2750px;
  }
}

.mint-container {
  --border-width: 2px;

  width: 40vw;
  background-color: rgba(var(--dark-rgb), 0.9);
  border-radius: 50px;
  color: var(--light);
  overflow-y: auto;
  padding: 50px;
  line-height: 45px;
  font-family: "Poppins", sans-serif;
  display: table-cell;
  vertical-align: middle;

  @media (min-width: 390px) and (max-width: 992px) {
    min-height: 65vh;
    width: 90vw;
  }

  @media only screen and (max-width: 390px) {
    min-height: 90vh;
    width: 90vw;
  }

  @media (min-width: 390px) and (max-width: 390px) {
    min-height: 70vh;
    width: 90vw;
  }
}

$shooting-time: 5s;

.shooting_star {
  position: absolute;
  left: 0%;
  height: 1px;
  z-index: 99;
  background: linear-gradient(-45deg, rgb(227, 255, 202), rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px rgb(241, 246, 255));
  animation: tail $shooting-time ease-in-out infinite,
    shooting $shooting-time ease-in-out infinite;

  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      $delay: random(9999) + 0ms;
      top: calc(50% - #{random(600) - 300px});
      animation-delay: $delay;

      &::before,
      &::after {
        animation-delay: $delay;
      }
    }
  }
}

@keyframes tail {
  0% {
    width: 0;
  }

  30% {
    width: 100px;
  }

  100% {
    width: 0;
  }
}

@keyframes shining {
  0% {
    width: 0;
  }

  50% {
    width: 30px;
  }

  100% {
    width: 0;
  }
}

@keyframes shooting {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(1920px);
  }
}

.parallax {
  width: 100vw;
  height: 100vh;
  position: relative;
}
